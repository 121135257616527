
import { useState, useEffect } from 'react';


export const useBalanceList = (dataList : any[], refs : any, addressField : string ) => {
    const [balanceList, setBalanceList] = useState<Balance[]>([]);

    useEffect(() => {
        const fetchBalances = async () => {
            // Chuyển đổi dữ liệu thành list baseVault
            let list = dataList?.map(item => item[addressField]);
            list = list.filter(item => item !== null);

            const batchSize = 5; // Chia mỗi batch thành 5 phần tử
            const batchRequests = [] as any[];

            // Chia danh sách thành các batch nhỏ
            for (let i = 0; i < list.length; i += batchSize) {
                const batch = list.slice(i, i + batchSize);
                batchRequests.push(batch);
            }

            if (refs.balance.current === true && list.length > 0) {
                const fetchPromises = batchRequests.map(batch => 
                    fetch('https://api-sol.dexbull.com/balance/bulk/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(batch),
                    })
                    .then(response => response.json())
                    .then(data => data)
                    .catch(error => {
                        console.log('Fetch Error:', error);
                    })
                );

                // Chờ tất cả các request hoàn thành
                Promise.all(fetchPromises)
                    .then(results => {
                        // Gộp tất cả dữ liệu lại và cập nhật balanceList
                        const allBalances = results.flat();
                        setBalanceList(allBalances);
                        refs.balance.current = false;
                    })
                    .catch(error => {
                        console.log('Error processing all batches:', error);
                    });
            }
        };

        fetchBalances();
    }, [dataList, refs.balance]);

    return balanceList;
};


export const useDexpaidList = (dataList : any[], refs : any) => {
    const [dexpaidList, setDexpaidList] = useState<DexpaidOrder[]>([]);
    useEffect(() => {
       if(refs.dexpaid.current === true){
         fetch('https://api.ledinhhuy.com/dexpaid/chain/solana?limit=50')
           .then(response => {
               return response.json(); 
           })
           .then(data => {
               setDexpaidList(data);
               refs.dexpaid.current = false; // Reset cờ sau khi xử lý xong
           })
           .catch(error => {
               console.log('Fetch Error:', error);
           });
       }
    },[dataList, refs.dexpaid]);

    return dexpaidList;
};