import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../../logo-white.png';


export default function MenuNavigation() {
    const [activeItem, setActiveItem] = useState('home'); // initialize state
    const location = useLocation(); // get current URL

    useEffect(() => {
        if (location.pathname === '/') {
          setActiveItem('home');
        } 
        else if (location.pathname === '/tokens') {
          setActiveItem('tokens');
        } 
       
        
      }, [location]); 


    const handleMenuClick = (event, path) => {
        if (event.button === 1) { 
            window.open(path, '_blank');
        }
        else {
            if (location.pathname === path) {
                window.location.reload();
            } else {
                window.location.href = path;
            }
        }
        
        event.preventDefault();
    };


    return (
        //navigation
        <>
            <div className="menu-option">
                <button onMouseDown={(e) => handleMenuClick(e, '/')} className={activeItem === 'home' ? 'active cursor-pointer' : 'cursor-pointer'}>
                    <img className='max-sm:w-9 md:w-8' src={Logo} alt="TokenCheck" /> 
                </button>
            </div>
            <div className="menu-option whitespace-nowrap ml-4">
                <button onMouseDown={(e) => handleMenuClick(e, '/tokens')}  className={activeItem === 'tokens' ? 'active cursor-pointer underline underline-offset-4 text-highlight' : 'cursor-pointer'}>
                    <span className="text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50">New Pair</span>
                </button>
            </div>
        </>
    );

}
