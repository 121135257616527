import React, { useState, useEffect , useRef} from 'react';
import { varTime,  solNumber, ratioChange, makePercent, showRateToken, showRateMintAuthority, showRateDexpaid, initSOL, checkPump, initToken, solCap, displayRate, RateType } from '../../components/Helper';
import Modal from '../../components/wallet/Modal';
import { useLocation, useHistory } from 'react-router-dom';
import { LuCopy , LuCopyCheck} from "react-icons/lu";
import { useBalanceList, useDexpaidList } from 'src/components/Utils';
import { ReactComponent as IC_ADS } from '../../ic_ads.svg';
import { shortAddress } from 'src/components/Link';

/* eslint-disable react-hooks/exhaustive-deps */
const TokensArea = () => {
    const refs = {
        // complete: useRef(true),
        // walletToken: useRef(true),
        balance: useRef(true),
        dexpaid : useRef(true),
    };

    const [page, setPage] = useState(1);
    const nextPage = () => {setPage(page + 1)};
    const prevPage = () => {if(page > 1) setPage(page - 1)};

    const [dataList, setDataList] = useState<Token[]>([]);
    const [filter, setFilter] = useState({});

    const balanceList = useBalanceList(dataList, refs, "baseVault");
    const dexpaidList = useDexpaidList(dataList, refs);


    const handleCopy = (mint) => {
      // Copy data to clipboard
      navigator.clipboard.writeText(mint).then(() => {
        // Update state to change icon for the clicked row
        setDataList((prevRows) =>
          prevRows.map((row) =>
            row.mint === mint ? { ...row, copied: true } : row
          )
        );
  
        //Reset the icon state after 2 seconds
        setTimeout(() => {
          setDataList((prevRows) =>
            prevRows.map((row) =>
              row.mint === mint ? { ...row, copied: false } : row
            )
          );
        }, 2000);
      });
    };



    /*******************************************
    * AREA : MODAL / OPEN LINK
    *******************************************/
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {setIsModalOpen(true);}
    const closeModal = () => {setIsModalOpen(false);}

    let history = useHistory();
    const openLink = (event, mintToken) => {
      if (event.button === 1) { 
          window.open('/token/' + mintToken, '_blank');
      }
      else  history.push('/token/' + mintToken);

      event.preventDefault(); // Prevent default behavior (if any)
    }

    const doubleClick = (event, mintToken) => {
      history.push('/token/' + mintToken);
      event.preventDefault(); // Prevent default behavior (if any)
    }

    /*******************************************
     * AREA : SEARCH
     *******************************************/
    const [search, SetSearch] =  useState(null);
    const [checkSearch, setCheckSearch] = useState(false);
    let location = useLocation();

    
    
    useEffect(() => {
      // Kiểm tra xem URL có tham số 's' không
      const searchParams = new URLSearchParams(location.search);
      const s = searchParams.get('search');
      if (s) {
        SetSearch(s);
        setFilter({ ...filter, "search" : s});
      }
      setCheckSearch(true);

    }, []);

    /*******************************************
     * AREA : FILTER 
     *******************************************/

    const [checkedItems, setCheckedItems] = useState({
      pinksale: false,
      pumpfun: false,
      moonshot: false,
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
       
        setPage(1);

        setCheckedItems({
          ...checkedItems,
          [name]: checked,
        });
        

        if(checked){
          setFilter({ ...filter, presale : name});

          var new_filter = {pinksale: false, pumpfun: false, moonshot: false};
          new_filter[name] = true;
          setCheckedItems(new_filter);
        }
        else {
          setFilter({});
        }
        // openModal();
    };


    const [next, setNext] = useState(false);
    const handleNext = (event) => {
      const { checked } = event.target;
      setNext(checked);
      setFilter({ ...filter, next : checked});
    }

    const [burn, setBurn] = useState(false);
    const handleBurn = (event) => {
      const { checked } = event.target;
      setBurn(checked);
      setFilter({ ...filter, burn: checked});
    }

    const [dexpaid, setDexpaid] = useState(false);
    // dex paid
    const handleDexpaid = (event) => {
      const { checked } = event.target;
      setDexpaid(checked);
      setFilter({ ...filter, dexpaid : checked});
    }

    /*******************************************
     * AREA : LOAD DB 
     *******************************************/

   
    useEffect(() => {
        if(checkSearch === false) return;

        // const limit = 10;
        // const offset = limit * (page-1);
        // let params = {"offset" : offset , "limit" : limit , "sort" : "blockTime", "order" : "DESC"};
        // params["filter"] = filter;

        fetch(`https://api.dexbull.com/tokens/solana?limit=10`,
            {
                method: 'GET',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
                //body: JSON.stringify(params)
            }
          )
          .then(response => {
            return response.json(); 
          })
          .then(data => {
            const formattedPair: Token[] = data.map((item: any) => ({
                name : item.meta?.name,
                symbol : item.meta?.symbol,
                image : item.meta?.image,

                mint : item.mint,
                creation_ts: item.creation_ts,
                info : item.info, 
                // poolId: item.poolId,
                // createdAt : item.createdAt,
                // txId : item.txId,
                baseVault : item.pool?.baseVault,
                authority : item.pool?.initLiquidity?.authority,
                init_coin_amount : initSOL(item.pool),
                init_pc_amount : initToken(item.pool),

                isNew : false,
            }));

            setDataList(formattedPair);  
          })
          .catch(error => {
            console.log('Fetch Error:', error);
          });
      
  }, [page, filter, checkSearch]);


  return (
      <div className="container mx-auto md:mt-4 max-sm:p-4">
          
          {/* Filter Options */}
          <div className="flex gap-4" key="filter">
              <div className="max-sm:w-full x-board px-2 py-1 flex gap-2">
                  
                  <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="pumpfun"
                      checked={checkedItems.pumpfun}
                      onChange={handleChange}

                    />
                    Pump
                  </label>

                  <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="moonshot"
                      checked={checkedItems.moonshot}
                      onChange={handleChange}

                    />
                    Moonshot
                  </label>
            </div>

            <div className="max-sm:w-full x-board px-2 py-1 flex gap-2">
                <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="burn"
                      checked={burn}
                      onChange={handleBurn}

                    />
                    Burn
                </label>

                <label className="flex items-center cursor-pointer">
                    <input 
                      type="checkbox" className='mr-1'
                      name="dexpaid"
                      checked={dexpaid}
                      onChange={handleDexpaid}
                    />
                    DexPaid
                </label>

            </div>
          </div>
          {/* Table - token list */}
          <div className="overflow-x-auto	pb-10">
            <table className="table-auto w-full  mt-4 table-tokens" key="list">
              <thead> 
                  <tr className="uppercase">
                    <th className="border border-gray-600 x-2 py-2 sticky left-0  z-10 bg-main">IC</th>
                    <th className="border border-gray-600 py-2">Age</th>

                    <th className="border border-gray-600 py-2">Name</th>

                    <th className="border border-gray-600 py-2">C/A</th>
                    
                    <th className="border border-gray-600 py-2" colSpan={2}><div className='md:flex items-center justify-center'><div>Init</div> <div className="text-xs md:ml-2 text-slate-400">(SOL | Token)</div></div></th>
                    
                    <th className="border border-gray-600 py-2"><div className='md:flex items-center justify-center'><div>Liquid</div><div className="text-xs md:ml-2 text-slate-400">(SOL)</div> </div></th>
                    <th className="border border-gray-600 py-2">Rate</th>
                  </tr>
              </thead>
              <tbody className='x-board'>
                {dataList.map((item, index) => {
                    const check_balance = balanceList?.find(p => p?.address === item.baseVault);
                    const check_dexpaid = dexpaidList?.find(p => p?.tokenAddress === item.mint);

                    return (

                      
                        <tr key={index} className="max-sm:align-top hover:bg-slate-900 cursor-pointer x-border tr-token" onDoubleClick={(event) => {return; doubleClick(event, item.mint)}} onMouseDown={(event) => {return;openLink(event, item.mint)}} >
                              
                              <td className="px-2 py-2  left-0  max-sm:sticky max-sm:z-10 bg-stick max-sm:flex" onMouseDown={(event) => {openLink(event, item.mint)}}>
                                  <div class="relative min-w-fit rounded-full">
                                      <img src={item.image} className="h-8 w-8 rounded-full border-2 md:h-10 md:w-10 border-rebrand-chain-solana token-image-ic" alt={item.name} />
                                      {
                                          checkPump(item) === 1?(<div className="absolute right-[-3px] bottom-[9px] max-sm:bottom-[-3px] flex rounded-full bg-black p-[1px]">
                                                                <img src="/images/exchanges/pumpfun.png" alt="pump-fun" className="h-[15px] w-[15px] rounded-full bg-white sm:h-[18px] sm:w-[18px]"/>
                                                            </div>):('')
                                                            
                                      }

                                      {/* {
                                          item.moonshot === 1?(<div className="absolute right-[-3px] bottom-[9px] max-sm:bottom-[-3px] flex rounded-full bg-black p-[1px]">
                                                                <img src="/images/exchanges/moonshot.png" alt="pump-fun" className="h-[15px] w-[15px] rounded-full bg-white sm:h-[18px] sm:w-[18px]"/>
                                                            </div>):('')
        
                                      }  */}
                                      
                                  </div>
    
                              </td>
                              <td className="max-sm:px-2 max-sm:py-2 md:px-4 min-w-20" onMouseDown={(event) => {openLink(event, item.mint)}}>{varTime(item.creation_ts/1000)}</td>

                              <td className="max-sm:px-2 max-sm:py-2 md:px-4 whitespace-nowrap fle " onMouseDown={(event) => {openLink(event, item.mint)}}>
                                      <div className="mr-2 max-w-40 text-wrap max-sm:hidden">{item.name}</div>
                                      <div className="text-slate-500 ">{item.symbol} </div>

                              </td>
                              <td className="max-sm:px-2 max-sm:py-2 md:px-4">
                                  <div className="flex items-center"><span className="max-sm:hidden" onMouseDown={(event) => {openLink(event, item.mint)}}>{shortAddress(item.mint)}</span> 
                                  <button className="px-2 py-2" onClick={() => handleCopy(item.mint)}>{item.copied ?<span className="text-green-500"><LuCopyCheck/></span>:<span className="text-slate-500"><LuCopy /></span>}</button></div>
                                </td>
                              
                              <td className="max-sm:px-2 max-sm:py-2 md:px-4" onMouseDown={(event) => {openLink(event, item.mint)}}>
                                <div className='md:flex'>
                                  <div className='w-full'>{item.init_coin_amount}</div>
                                  <div className='md:float-right max-sm:text-xs items-center text-slate-400  whitespace-nowrap'>{item.info?makePercent(item.init_pc_amount, item.info.supply) + "%": ""} </div>
                                </div>
                              </td>
                              <td className="max-sm:px-2 max-sm:py-2 md:px-4"></td>
                              
                              
                              <td className="max-sm:px-2 max-sm:py-2 md:px-4" onMouseDown={(event) => {openLink(event, item.mint)}}>
                                <div className='md:flex'>
                                  {check_balance?<><div className='w-full'>{solCap(check_balance.balance)}</div>
                                      <div className='md:float-right max-sm:text-xs items-center whitespace-nowrap'>{ratioChange(check_balance.balance, item.init_coin_amount * 10**9)} </div>
                                    </>
                                  :'-'
                                
                                  }
                                </div>
                              </td>
                              <td className="max-sm:px-2 max-sm:py-2 md:px-4" onMouseDown={(event) => {openLink(event, item.mint)}}>
                                    <div className='md:flex'> 
                                        {/* {showRateToken(item.burn_block, item.burn, item.remove, 0)} 
                                        {showRateMintAuthority(item.mintuthority)}
                                        {showRateDexpaid(item.dexpaid, item.moonshot)}  */}

                                        {check_dexpaid?(<div className='flex items-center gap-1'>{displayRate(RateType.GOOD)}<IC_ADS/><span>{varTime(check_dexpaid.paymentTimestamp / 1000)}</span></div>):"-"}
                                        </div>
                              </td>



                            
                        </tr> 
                      
                    )
                })}
              </tbody>
            </table>
          </div>
          {/* Navigation - page */}
          <div className="w-full mx-auto  text-center fixed z-30 bottom-0 left-0 right-0 bg-head text-highlight p-1"> 
              <div className="">
                  <div className="flex justify-center space-x-2">
                      <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={prevPage}>[ &lt;&lt; ]</button>
                      <span>{page}</span>
                      <button className="text-sm hover:font-bold hover:bg-transparent hover:text-slate-50" onClick={nextPage}>[ &gt;&gt; ]</button>
                  </div>
              </div>
          </div>
          {/* Login popup */}
          <Modal isOpen={isModalOpen} onClose={closeModal} />
      </div>
  )
}

export default TokensArea