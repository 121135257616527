
const  TelegramLogin = () => {

    return (
                <div className="w-full">
                    <div className="float-right flex">
                        [Login]
                    </div>                    
                </div>
           
        
    );
};

export default TelegramLogin;