import IC_ADS from '../ic_ads.svg';

export function pumpLink(mint : string){
    const link = "https://pump.fun/coin/" + mint;
    return (<a target="_blank" rel="noreferrer" className="mr-2 underline" href={link}>Pump.fun</a>);
}


export function raydiumLink(mint : string , isRaydium : number){
    if(isRaydium === 1){
        const link_raydium = 'https://v2.raydium.io/swap/?outputCurrency=' + mint + '&inputCurrency=sol';
        return (<a href={link_raydium} target="_blank"  rel="noreferrer" className="mr-2 underline">Raydium</a>);
    }
    else return;
}

export function dexscreenerLink(mint : string, marketDex : number){
    if(marketDex != null){
        const link_dexscreener = 'https://dexscreener.com/solana/' + mint;
        return (<a href={link_dexscreener} target="_blank" rel="noreferrer " className="mr-2 underline">Dexscreener</a>);
    }
    else return;
}

export function pumpProfile(username, wallet){
    const profile_link = 'https://pump.fun/profile/' + wallet;

    if(username != null){
        return (<a target="_blank" rel="noreferrer" className="border border-slate-400 bg-green-700 rounded-md pl-1 pr-1 text-sm" href={profile_link}>{username}</a>);
    }
    else {
        const short_name = wallet.substr(0,6);
        return (<a target="_blank" rel="noreferrer" className="border border-slate-400 bg-blue-700 rounded-md pl-1 pr-1  text-sm" href={profile_link}>@{short_name}</a>);
    }
}


export  function metaLink(type : string, link : string, dexpaidInfo : any){ //account tx token
   let finalLink : string;
   let isDexLink = false; 
   if(dexpaidInfo && dexpaidInfo[type]) {
        isDexLink = true;
        finalLink = dexpaidInfo[type];
   }
   else finalLink = link;

   if(!finalLink) return "-";

   return (<div class="flex items-center gap-2"><a className="text-link" target="_blank" rel="noreferrer"  href={finalLink}>{finalLink}</a> {isDexLink?(<img className="w-4 h-4" src={IC_ADS}/>):("")}</div>);
}


export  function shortAddress2(address) {
    if(address == null || address.length < 10) return "";
    const firstForChars = address.substr(0,2);
    const lastForChars  = address.substr(-2);
    return firstForChars + "..." + lastForChars;
};

  
export  function tokenLink(address){ //account tx token
    return '/token/' + address;
}

export  function solscanLink(address, type ){ //account tx token
    return 'https://solscan.io/'+ type + '/' + address;
}

export  function shortAddress(address) {
    if(address == null || address.length < 10) return "";
    const firstForChars = address.substr(0,5);
    const lastForChars  = address.substr(-4);
    return firstForChars + "..." + lastForChars;
};


export function shortTokenLink(address, type){
    if(address == null || address.length < 10) return "";
    const short_Token = shortAddress(address);
    const sol_scanLink = solscanLink(address, type);
    return (<a className="text-link" target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}



export function shortTokenLink2(address, type){
   if(address == null || address.length < 10) return "";
   const short_Token =  shortAddress2(address);
   const sol_scanLink = solscanLink(address, type);
   return (<a className="text-link" target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}

export function shortMintAuthorityLink(address, type){
   if(address == null || address.length < 10) return "-";
   const short_Token =  shortAddress2(address);
   const sol_scanLink = solscanLink(address, type);
   return (<a className='color-normal' target="_blank" rel="noreferrer"  href={sol_scanLink}>{short_Token}</a>);
}